<template>
  <div id="app">
    <div
      v-if="$root.ready && overflow"
      class="ready-overflow"
      @click="openWebsite()"
    >
      <button
        class="btn btn-outline-primary text-uppercase"
        @click="openWebsite()"
      >
        Buka Undangan
      </button>
    </div>

    <AGWAudio
      :file="music.file"
      :loop="music.loop"
      :autoplay="music.autoplay"
      ref="audio"
    />
    <AGWHero
      overlay
      :images="backgrounds"
      ref="hero"
      parallax
      target="#intro"
    />

    <div
      class="agw-content bg-white"
      :style="{ 'margin-top': `${$root.hero.height}px` }"
    >
      <AGWIntro id="intro" />

      <AGWPawiwahan id="pawiwahan" class="bg-blue-light py-5" />
      <AGWMepandes id="mepandes" class="pad-y-60" :mepandes="mepandes" />

      <AGWCounter
        id="counter"
        class="pad-y-60"
        :background="background"
        :date="time.start"
      />
      <AGWGallery id="gallery" class="py-5" />

      <div
        class="mt-4 w-100 relative"
        style="height: 250px;background: url('https://ik.imagekit.io/aryraditya/ceremonyku/krisna-trisna/bambookuningrestaurant_01_srr7DduvQ.jpg?tr=w-800,h-347,fo-custom,cm-extract');background-repeat: no-repeat;background-size: cover;"
      >
        <div style="height: 100%; width: 100%; background: rgba(0, 0, 0, .4); color: #fff; top: 0; left: 0; right: 0; bottom: 0; display: flex; justify-content: center; align-items: center; flex-wrap: wrap">
          <div>
            <div style="font-size: 2rem; text-transform: uppercase; letter-spacing: 2px; width: 100%; text-align: center;">Vanue</div>
            <div style="font-size: 1.75rem; text-transform: uppercase; letter-spacing: 2px; width: 100%; text-align: center;">Amatara Royal Ganesha - Ubud</div>
          </div>
        </div>
      </div>
      <AGWMap
        :height="450"
        url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3945.4865692065964!2d115.26345381470811!3d-8.549120993854665!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd23deb8b14cd5b%3A0xe84d961a79efdf5f!2sAmatara%20Royal%20Ganesha%20-%20Ubud!5e0!3m2!1sen!2sus!4v1613875002200!5m2!1sen!2sus"
        directionUrl="https://www.google.com/maps/dir//Amatara+Royal+Ganesha+-+Ubud,+Jl.Raya+Lodtunduh+No.88,+Br.+Kelingkung+No.Ds,+Lodtunduh,+Kecamatan+Ubud,+Kabupaten+Gianyar,+Bali+80571,+Indonesia/@-8.549121,115.2634538,17z/data=!4m19!1m9!3m8!1s0x2dd23deb8b14cd5b:0xe84d961a79efdf5f!2sAmatara+Royal+Ganesha+-+Ubud!5m2!4m1!1i2!8m2!3d-8.549121!4d115.2656425!4m8!1m0!1m5!1m1!1s0x2dd23deb8b14cd5b:0xe84d961a79efdf5f!2m2!1d115.2656425!2d-8.549121!3e0"
        :location="location"
        id="map"
      />
    </div>

    <div class="agw-content" style="background: transparent">
      <Himbauan />
      <attendance id="attendance" class="pad-y-60"></attendance>
    </div>

    <section class="footer bg-white pad-y-20" ref="footer">
      <div class="font-weight-lighter">
        &copy; {{ $moment().format("YYYY") }}. Made by
        <a href="https://ceremonyku.com">ceremonyku</a>.
      </div>
      <div class="w-full flex justify-center mt-2 mb-2">
        <a href="https://fb.com/ceremonyku" target="_blank" class="mx-2"
          ><svg
            id="facebook"
            xmlns="http://www.w3.org/2000/svg"
            width="26.694"
            height="26.694"
            viewBox="0 0 26.694 26.694"
          >
            <path
              id="Path_444"
              data-name="Path 444"
              d="M5.966,3H26.728a2.966,2.966,0,0,1,2.966,2.966V26.728a2.966,2.966,0,0,1-2.966,2.966H5.966A2.966,2.966,0,0,1,3,26.728V5.966A2.966,2.966,0,0,1,5.966,3M25.245,5.966H21.537a5.19,5.19,0,0,0-5.19,5.19v3.707H13.381v4.449h2.966V29.694H20.8V19.313h4.449V14.864H20.8V11.9a1.483,1.483,0,0,1,1.483-1.483h2.966Z"
              transform="translate(-3 -3)"
              fill="#283a60"
            ></path></svg
        ></a>
        <a
          href="https://www.instagram.com/ceremonyku/"
          target="_blank"
          class="mx-2"
          ><svg
            id="instagram"
            xmlns="http://www.w3.org/2000/svg"
            width="26.694"
            height="26.694"
            viewBox="0 0 26.694 26.694"
          >
            <path
              id="Path_443"
              data-name="Path 443"
              d="M9.741,2H20.953a7.747,7.747,0,0,1,7.741,7.741V20.953a7.741,7.741,0,0,1-7.741,7.741H9.741A7.747,7.747,0,0,1,2,20.953V9.741A7.741,7.741,0,0,1,9.741,2M9.474,4.669a4.8,4.8,0,0,0-4.8,4.8V21.22a4.8,4.8,0,0,0,4.8,4.8H21.22a4.8,4.8,0,0,0,4.8-4.8V9.474a4.8,4.8,0,0,0-4.8-4.8H9.474m12.88,2A1.668,1.668,0,1,1,20.686,8.34a1.668,1.668,0,0,1,1.668-1.668m-7.007,2a6.673,6.673,0,1,1-6.673,6.673,6.673,6.673,0,0,1,6.673-6.673m0,2.669a4,4,0,1,0,4,4,4,4,0,0,0-4-4Z"
              transform="translate(-2 -2)"
              fill="#283a60"
            ></path></svg
        ></a>
        <a href="https://wa.me/6285739137858" target="_blank" class="mx-2"
          ><svg
            id="whatsapp"
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="26"
            viewBox="0 0 26 26"
          >
            <path
              id="Path_445"
              data-name="Path 445"
              d="M21.175,17.548c.325.169.533.26.6.39a2.559,2.559,0,0,1-.273,1.534,3.166,3.166,0,0,1-2.21,1.456c-.6.026-.611.468-3.848-.949s-5.187-4.875-5.343-5.1A6.3,6.3,0,0,1,8.9,11.49a3.543,3.543,0,0,1,1.235-2.652.969.969,0,0,1,.884-.338h.611c.2,0,.468-.078.715.585l.9,2.431a.593.593,0,0,1,.013.572l-.351.533-.507.546c-.156.156-.338.325-.156.65a9.648,9.648,0,0,0,1.716,2.314,8.59,8.59,0,0,0,2.535,1.69c.312.182.507.156.7-.052l1.053-1.222c.247-.325.455-.247.754-.143l2.171,1.144M15,2A13,13,0,1,1,8.045,25.985L2,28l2.015-6.045A13,13,0,0,1,15,2m0,2.6A10.4,10.4,0,0,0,6.5,20.993L5.25,24.75,9.007,23.5A10.4,10.4,0,1,0,15,4.6Z"
              transform="translate(-2 -2)"
              fill="#283a60"
            ></path></svg
        ></a>
      </div>
    </section>

    <transition name="slide-fade">
      <guest-box :floating="true" v-if="$root.scroll.Y > $root.hero.height" />
    </transition>

    <div class="snackbar" v-if="sw.updateExists">
      New version available!
      <button @click="refreshApp" class="btn">REFRESH</button>
    </div>
  </div>
</template>

<script>
const ImageHero1 =
  "https://ik.imagekit.io/aryraditya/ceremonyku/krisna-trisna/SKF_3578_ambNl_yyVy.jpg?tr=w-800";
const ImageHero2 =
  "https://ik.imagekit.io/aryraditya/ceremonyku/krisna-trisna/SKF_3500_zK-6Logdos.jpg?tr=w-800";
const ImageHero3 =
  "https://ik.imagekit.io/aryraditya/ceremonyku/krisna-trisna/SKF_3347_w36KmqkJS.jpg?tr=w-800";

import Audio from "@/assets/audio/backsound.mp3";

import AGWHero from "@/components/Hero/HeroTwo";
import AGWIntro from "@/components/Intro";
import AGWPawiwahan from "@/components/Pawiwahan";
import AGWMepandes from "@/components/Mepandes";
import AGWCounter from "@/components/Counter";
import AGWGallery from "@/components/Gallery/GalleryOne";
import AGWMap from "@/components/Maps";
import AGWAudio from "@/components/Audio";
import GuestBox from "@/components/GuestBox";
import Attendance from "@/components/Attendance";
import Himbauan from './components/Himbauan.vue';

import { setInterval } from "timers";

export default {
  name: "app",
  components: {
    AGWHero,
    AGWIntro,
    AGWPawiwahan,
    AGWMepandes,
    AGWCounter,
    AGWGallery,
    AGWMap,
    AGWAudio,
    GuestBox,
    Attendance,
    Himbauan,
  },
  data() {
    return {
      title: "Pawiwahan",
      backgrounds: [ImageHero1, ImageHero2, ImageHero3],
      bgActive: 0,
      dividerHeight: 0,
      overflow: true,
      location: "Amatara Royal Ganesha Hotel And Resort",
      // date: "2020-04-05",
      time: {
        start: this.$moment("2021-04-05 10:00"),
        end: "20:00",
      },
      wedding: {
        man: {
          name: "Sang Putu Krisna Adhi Pranata, SE., MM",
          surname: "Krisna",
          parents: "Sang Nyoman Suasa & Desak Made Sudarmini",
          description: "Putra pertama",
          address: "Gang Batur Sari 4, No 2, Sading, Mengwi, Badung",
          photo:
            "https://ik.imagekit.io/aryraditya/ceremonyku/krisna-trisna/SKF_3603_o0Npqk0e1I.jpg?tr=w-400",
        },
        woman: {
          name: "Ns. Dewa Ayu Sri Trisna Artha Dewi, S.Kep",
          surname: "Trisna",
          parents: "Dewa Putu Karya & Dewa Ayu Yuliani",
          address: "Br Tebongkang, Singakerta, Ubud, Gianyar",
          description: "Putri kedua",
          photo:
            "https://ik.imagekit.io/aryraditya/ceremonyku/krisna-trisna/SKF_3451_1-uPIsAyK.jpg?tr=w-400",
        },
      },
      mepandes: [],
      gallery: [],
      music: {
        file: Audio,
        autoplay: true,
        loop: true,
      },
      sw: {
        refreshing: false,
        registration: null,
        updateExists: false,
      },
    };
  },
  computed: {
    background() {
      return this.backgrounds[this.bgActive];
    },
  },
  mounted() {
    this.dividerHeight = `calc(100vh - ${this.$refs.footer.offsetHeight}px)`;
    setInterval(() => {
      let active = this.bgActive + 1;
      if (active >= this.backgrounds.length) {
        active = 0;
      }

      this.bgActive = active;
    }, 5000);
  },
  created() {
    document.addEventListener("swUpdated", this.showRefreshUI, { once: true });
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (this.sw.refreshing) return;
        this.sw.refreshing = true;
        window.location.reload();
      });
    }
  },
  methods: {
    showRefreshUI(e) {
      this.sw.registration = e.detail;
      this.sw.updateExists = true;
    },
    refreshApp() {
      this.sw.updateExists = false;
      if (!this.sw.registration || !this.sw.registration.waiting) {
        return;
      }
      this.sw.registration.waiting.postMessage("skipWaiting");
    },
    openWebsite() {
      this.overflow = false;
      document.querySelector("#loading").classList.remove("active");
      if (this.$refs.audio) this.$refs.audio.play();
    }
  },
};
</script>
